import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Image, Grid, Row, Alert } from 'react-bootstrap';

import { isSupportedUserAgent } from '../../core/util';
import s from './LandingPage.css';

import NetworkContent from '../NetworkContent';
import CommunityContent from '../community/CommunityContent';
import LordOfTheSearch from '../search/LordOfTheSearch';

import { NETWORK_PAGE } from '../../settings';
import { setSearchTarget } from '../../actions/NetworkActions';

const notSupportedWarning =
  'Your browser is no longer supported and you may encounter unexpected issues while using HumanBase. ' +
  'For the best experience please consider upgrading or switching to another browser.';

const getMaintenanceMessage = (startDate, endDate) => {
  const formatDate = date => {
    return date.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
    });
  };

  return (
    `HumanBase will be unavailable ${formatDate(startDate)} - ${formatDate(
      endDate,
    )} for scheduled maintenance. ` +
    'We apologize for any inconvenience this may cause.'
  );
};

const LandingPage = () => {
  const dispatch = useDispatch();
  const searchTarget = useSelector(state => state.currentSearch.searchTarget);

  useEffect(() => {
    if (!searchTarget) dispatch(setSearchTarget(NETWORK_PAGE.pageName));
  }, [dispatch, searchTarget]);

  let content = <NetworkContent />;
  if (searchTarget === 'module') {
    content = <CommunityContent />;
  }

  const shouldShowMaintenance = (startDate, endDate) => {
    const weekBefore = new Date(startDate);
    weekBefore.setDate(startDate.getDate() - 7);
    const now = new Date();
    return now >= weekBefore && now <= endDate;
  };

  const startDate = new Date('2025-02-10T12:00:00Z'); // Noon UTC
  const endDate = new Date('2025-02-13T12:00:00Z');
  const maintenanceWarning = getMaintenanceMessage(startDate, endDate);

  return (
    <div className={s.root}>
      <Grid fluid={false}>
        <Row>
          <div className={s.banner}>
            <Image src="/hb_full_logo.svg" className={s.logo} />
            <div className={s.lead}>
              <p className="lead">
                data-driven predictions of gene expression, function,
                regulation, and interactions in human
              </p>
            </div>
            {shouldShowMaintenance(startDate, endDate) && (
              <div>
                <Alert bsStyle="info">
                  <i
                    className="fa fa-info-circle"
                    style={{ marginRight: '8px' }}
                  />
                  {maintenanceWarning}
                </Alert>
              </div>
            )}
            {!isSupportedUserAgent() && (
              <div>
                <Alert bsStyle="warning">
                  <i className="fa fa-exclamation-triangle" />
                  {notSupportedWarning}
                </Alert>
              </div>
            )}
            <LordOfTheSearch />
          </div>
        </Row>
        {content}
      </Grid>
    </div>
  );
};

export default withStyles(s)(LandingPage);
